.left-content[data-v-0047b2c7] {
  width: 188px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-right: 1px solid #cccccc;
  background: #fff;
}
.left-content .img-content[data-v-0047b2c7] {
  padding: 3px;
  position: relative;
}
.left-content .img-content .img-item[data-v-0047b2c7] {
  height: 100px;
  padding: 3px;
}
.left-content .img-content .img-item img[data-v-0047b2c7] {
  width: 100%;
  height: 100%;
}
.left-content .img-content .img-item span[data-v-0047b2c7] {
  width: 100%;
  height: 100%;
  background: #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.left-content .img-content .img-item .delete-icon[data-v-0047b2c7] {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 16px;
  color: red;
}
.left-content .isActive[data-v-0047b2c7] {
  border: 1px solid #ccc;
  border-radius: 3px;
}
.common_tree_handle[data-v-0047b2c7] {
  min-width: 188px;
}
.input-new-tag[data-v-0047b2c7] {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
[data-v-0047b2c7] .el-form-item__content .el-tag {
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  margin-bottom: 5px;
}
[data-v-0047b2c7] .el-form-item__content .el-tag .el-icon-close {
  font-size: 16px;
}